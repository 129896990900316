const PRICING_STATUS_CODE_AR = "Final-AR";
const PRICING_STATUS_CODE_P = "Preliminary";
const PRICING_STATUS_CODE_O = "Open";
const PRICING_STATUS_CODE_F = "Final";

const PRICING_STATUS_CODES_SUBMITTABLE = ['O','P'];

const getPricingStatusName = (code) => {
switch(code) {
    case 'AR':
        return PRICING_STATUS_CODE_AR;
    case 'P':
        return PRICING_STATUS_CODE_P;
    case 'O':
        return PRICING_STATUS_CODE_O;
    case 'F':
        return PRICING_STATUS_CODE_F;
    default:
        return 'No Code'
    }
}

const getPricingStatus = (item) => {
    let pricingStatusCode = 'N/A';
    // if (orderHeaders.length > 0) {
    //     const orderHeader = orderHeaders.filter(wo => ('woId' in item && wo.wordOrderId.toString() === item.woId.toString()));
    //     if (orderHeader.length > 0 && 'pricingStatus' in orderHeader[0] && orderHeader[0].pricingStatus !== null) {
    //         pricingStatusCode = orderHeader[0].pricingStatus;
    //     }
    // }

    if ('currentPricingStatus' in item) pricingStatusCode = item.currentPricingStatus;

    return {
        code: pricingStatusCode,
        name: getPricingStatusName(pricingStatusCode),
        isSubmittableToP3: isSubmittableToP3(pricingStatusCode)
    }
  };

  const isSubmittableToP3 = (code) => {
    return PRICING_STATUS_CODES_SUBMITTABLE.includes(code)
  }
  
  export default getPricingStatus;
  export { getPricingStatus };