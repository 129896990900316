/* eslint react/no-unused-state: 1 */
/* eslint no-undef: 1 */

import 'react-dates/initialize';
import React from 'react';
import { Logger } from 'aws-amplify';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import {
  DropDown, PrimaryButton, MultiSelectDropDown, Pulse
} from 'wf-react-component-library';
import { getCustomers, getVendors, getBuyers, getWorkOrderHeaders } from '../services/api/P3ApiService';
import PurchaseOrderDetails from './PurchaseOrderDetails';
import { getPurchaseOrders } from '../services/api/SCSApiService';
import ErrorModal from './ErrorModal';
import { calculatePurchaseOrderTotals } from '../utilities/calculations';

import 'react-dates/lib/css/_datepicker.css';
import './Search.scss';

import StickyWrapper from './StickyWrapper';

const logger = new Logger('Search.js');

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCustomers: null,
      customerDetails: this.props.customerDetails,
      dateOptions: {
        0: 'Load Date',
        1: 'Arrival Date',
      },
      startDate: null,
      endDate: null,
      dateType: null,
      isSearching: false,
      isLoadingCustomers: false,
      isLoadingVendors: false,
      displayNoItemsFound: false,
      selectedVendor: null,
      selectedBuyer: null,
      selectedBuyer: null,
      errorMsg: ''
    }

    this.onSelect = this.onSelect.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDropDownChange = this.handleDropDownChange.bind(this);
    this.resetPurchaseOrderDetails = this.resetPurchaseOrderDetails.bind(this);
    this.handleVendorChange = this.handleVendorChange.bind(this);
    this.handleBuyerChange = this.handleBuyerChange.bind(this);
  }

  componentDidMount = async() => {
    this.setState({ isLoadingCustomers: true, isLoadingVendors: true });
    
    await getCustomers().then((customerDetails) => {
      logger.debug('customers loaded', customerDetails);
      customerDetails.sort((a, b) => (a.code > b.code) ? 1 : -1);
      const data = customerDetails.map(details => {
        details['text'] = `${details.code} - ${details.description}`
        return details;
      })
      this.setState({ customerDetails: data, isLoadingCustomers: false });
    });

    await getVendors().then((vendorDetails) => {
      logger.debug('vendors loaded', vendorDetails);
      vendorDetails.sort((a, b) => (a.code > b.code) ? 1 : -1)
      let vendors = {};
      vendorDetails.forEach((vendor) => {
        const key = vendor.code;
        vendors[key] = `${vendor.code} - ${vendor.description}`;
      });
      // const vendors = keyValueMapper(vendorDetails, 'code', 'description');
      logger.debug('vendors mapped ...', vendors);
      this.setState({ vendors, vendorDetails, isLoadingVendors: false });
    });

    await getBuyers().then((buyerDetails) => {
      logger.debug('buyers loaded', buyerDetails);
      let buyers = {};
      buyerDetails.forEach((buyer) => {
        const key = `id-${buyer.buyerId}`;
        buyers[key] = buyer.description;
      });
      // const buyers = keyValueMapper(buyerDetails, 'buyerId', 'description');
      logger.debug('buyers mapped ...', buyers);
      this.setState({ buyers, buyerDetails });
    });
  }

  onSelect = (selectedList, selectedCustomer) => {
    this.resetPurchaseOrderDetails();
    this.setState({ selectedCustomers: selectedList });
  }

  onRemove = (selectedList, removedCustomer) => {
    this.resetPurchaseOrderDetails();
    this.setState({ selectedCustomers: selectedList });
  }

  handleSearch = async(buttonClick) => {
    this.setState({ isSearching: true, displayNoItemsFound: false });
    const { selectedBuyer, selectedVendor, selectedCustomers, startDate, endDate, dateType } = this.state;

    // Show error if search criteria not selected
    const isDateRangeSelected = startDate && endDate;
    const isVendorSelected = selectedVendor;
    const isCustomerSelected = selectedCustomers && selectedCustomers.length > 0;
    if (buttonClick && !isDateRangeSelected && !isVendorSelected && !isCustomerSelected) {
      this.setState({ errorMsg: 'Please select a vendor, customer, or date range.' });
      return;
    }

    // Show error if date type not selected
    const isDateSelected = startDate || endDate;
    const isDateTypeSelected = ['0', '1'].includes(dateType);
    if (buttonClick && isDateSelected && !isDateTypeSelected) {
      this.setState({ errorMsg: 'Please select a date for your search.' });
      return;
    }
    
    const customerCodes = selectedCustomers === null ? '' : selectedCustomers.map(cust => cust.code);
    const buyerId = selectedBuyer ? selectedBuyer.split('-')[1] : '';

    const params = {
      startDate: startDate === null ? null : startDate.format('YYYY-MM-DD'),
      endDate: endDate === null ? null : endDate.format('YYYY-MM-DD'),
      dateType: dateType,
      customerCode: customerCodes === '' ? customerCodes : customerCodes.join(','),
      vendorCode: selectedVendor === null ? '' : selectedVendor,
      buyerId: buyerId
    }
    
    getPurchaseOrders(params).then((purchaseOrderDetails) => {
      calculatePurchaseOrderTotals(purchaseOrderDetails);
      const workOrderIds = purchaseOrderDetails.filter(details => details.woId && details.woId !== null).map(details => details.woId);
      const workOrderHeaders = [];
      if (workOrderIds.length > 0) {
        getWorkOrderHeaders(workOrderIds.join(',')).then(headers => {
          purchaseOrderDetails = purchaseOrderDetails.map(item => {
            const orderHeader = headers.filter(wo => ('woId' in item && wo.wordOrderId.toString() === item.woId.toString()));
            if (orderHeader.length > 0 && 'pricingStatus' in orderHeader[0] && orderHeader[0].pricingStatus !== null) {
              item['currentPricingStatus'] = orderHeader[0].pricingStatus;
            }
            return item;
          });
          this.setState({purchaseOrderDetails, isSearching: false, displayNoItemsFound: purchaseOrderDetails.length === 0 });
        }).catch((error) => {
          logger.error('Error Retrieving Work Order Header', error);
        });
      } else {
        this.setState({purchaseOrderDetails, isSearching: false, displayNoItemsFound: purchaseOrderDetails.length === 0 });
      }
    }).catch((error) => {
      logger.error('Error Retrieving POs', error);
      this.setState({ displayNoItemsFound: true, isSearching: false })
    });
  }

  handleDropDownChange = (e) => {
    this.resetPurchaseOrderDetails();
    this.setState({ dateType: e.target.value });
  }

  handleVendorChange = (e) => {
    this.resetPurchaseOrderDetails();
    this.setState({ selectedVendor: e.target.value });
  }

  handleBuyerChange = (e) => {
    this.resetPurchaseOrderDetails();
    this.setState({ selectedBuyer: e.target.value });
  }

  resetPurchaseOrderDetails = () => {
    const { purchaseOrderDetails } = this.state;

    this.setState({
      isSearching: false, 
      displayNoItemsFound: false
    });

    if (purchaseOrderDetails !== undefined && purchaseOrderDetails.length > 0) {
      this.setState({  
        purchaseOrderDetails: [],
      });
    }
  }

  handleReset = () => {
    this.setState({
      dateType: null,
      startDate: null,
      endDate: null,
      selectedCustomers: null,
      selectedVendor: null,
      selectedBuyer: null,
      purchaseOrderDetails: [],
      isSearching: false, 
      displayNoItemsFound: false
    });

    // this.handleGetAllPurchaseOrders();
  }

  removeErrorMessages = () => this.setState({ errorMsg: '' });

  render() {
    const {
      customerDetails,
      dateOptions,
      selectedCustomers,
      purchaseOrderDetails,
      isSearching,
      isLoadingCustomers,
      displayNoItemsFound,
      dateType,
      vendors,
      selectedVendor,
      vendorDetails,
      selectedBuyer,
      buyers,
      buyerDetails
    } = this.state;

    const { currentUser } = this.props;

    return (
      <div className="App">
        { this.state.errorMsg.length > 0
          ? <ErrorModal showErrorModal={this.state.errorMsg.length > 0} errorMessages={this.state.errorMsg} closeModal={this.removeErrorMessages} />
          : null
        }
        <StickyWrapper>
          <div className="search-criteria flex-container-row">
            
            <div className="flex-container-column" style={{ paddingBottom: '30px', maxWidth: '250px' }}>
              { isLoadingCustomers || vendors === undefined
                ? <Pulse text="Loading Vendors..." />
                : <DropDown id="vendors" labelName="Vendor Name" options={vendors} value={selectedVendor === null ? '' : selectedVendor} placeHolderText="Select a Vendor" handleChange={this.handleVendorChange} />
              }    
            </div>
            
            <div className="flex-container-column" style={{ paddingBottom: '30px' }}>
                { isLoadingCustomers
                  ? <Pulse text="Loading Customers..." />
                  : <MultiSelectDropDown selectedValues={selectedCustomers} placeHolderText="Select a Customer(s)" labelName="Ship to Customer(s)" options={customerDetails} displayValue="text" id="customers" onSelect={this.onSelect} onRemove={this.onRemove} />
                }  
              </div>

            <div className="flex-container-column" style={{ paddingBottom: '30px' }}>
              <DropDown id="dateOptions" labelName="Date" options={dateOptions} value={dateType === null ? '' : dateType} placeHolderText="Select a Date Option" handleChange={this.handleDropDownChange} />
            </div>

            <div className="flex-container-column" style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '30px' }}>
              <div className="wf-form-element-label">
                From:
                <span style={{ paddingLeft: '110px' }}>To:</span>
              </div>
              <DateRangePicker
                startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                startDateId="start-dt" // PropTypes.string.isRequired,
                endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                endDateId="end-dt" // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) => {
                  this.resetPurchaseOrderDetails();
                  this.setState({ startDate, endDate })}
                }
                focusedInput={this.state.focusedInput}
                onFocusChange={(focusedInput) => this.setState({ focusedInput })}
                isOutsideRange={(day) => day.isBefore(moment().subtract(3, 'month'))}
                minimumNights={0}
                readOnly={true}
              />
            </div>

            <div className="flex-container-column" style={{ paddingBottom: '30px' }}>
              { buyers === undefined
                ? <Pulse text="Loading Buyers..." />
                : <DropDown placeHolderDisabled={true} id="buyers" labelName="Buyer" options={buyers} value={(selectedBuyer === null || selectedBuyer === '') ? '' : selectedBuyer} placeHolderText="Select a Buyer"  handleChange={this.handleBuyerChange} />
              }
            </div>

            <div className="flex-container-column">
              <div className="flex-container-row search-buttons">
                <PrimaryButton onClick={() => this.handleSearch(true)} name="Search" />
                <PrimaryButton onClick={this.handleReset} name="Reset" />
              </div>
            </div>
          </div>

          {purchaseOrderDetails === undefined || purchaseOrderDetails.length === 0 || isSearching
            ? '' 
            : <div className="flex-container-row" style={{ justifyContent: 'space-between', textAlign: 'center', paddingBottom: "10px" }}>
                <div className="flex-container-column xpo-count">
                  {`${purchaseOrderDetails.length} XPOs Returned`}
                </div>
              </div>
          }
        </StickyWrapper>

        { isSearching
          ? <div className="flex-container-row"><Pulse text="Loading Purchase Orders..." /></div>
          : null
        }

        { displayNoItemsFound
          ? <div className="flex-container-row">
              <div className="pulse-container headline-small">No POs Found</div>
            </div>
          : null
        }

        { purchaseOrderDetails === undefined || purchaseOrderDetails.length === 0 || isSearching
          ? null
          : <PurchaseOrderDetails buyerDetails={buyerDetails} handleSearch={this.handleSearch} vendorDetails={vendorDetails} searchId={Math.floor(1000000 * Math.random()) * 100000000} currentUser={currentUser} purchaseOrderDetails={purchaseOrderDetails} customerDetails={customerDetails}  />
        }
      </div>
    );
  }
}

export default Search;
