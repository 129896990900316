/* eslint-disable arrow-body-style */
import { API, Auth, Logger } from 'aws-amplify';

const logger = new Logger('SCSApiService.js');
const SCS_API_NAME = 'SCSApiProxy';

/**
 * Request Options to include authorization headers on all requests
 */
const requestOptions = () => {
  logger.debug('requestOptions..');
  return Auth.currentAuthenticatedUser()
    .then((user) => {
      return {
        headers: {
          'Content-Type': 'application/json',
          'x-api-authorization': `Bearer ${user.signInUserSession.accessToken.jwtToken}`
        },
      };
    })
    .catch((error) => {
      logger.error('User is not authenticated ', error);
      throw error;
    });
};

/**
 * Request Wrapper to call the request options on all requests
 * @param {*} request
 */
const requestWrapper = (request) => {
  return requestOptions().then((options) => request(options));
};

const putPurchaseOrder = (poId, body) => {
  return requestWrapper((options) => {
    // make api request
    Object.assign(options, { body });
    return API.put(SCS_API_NAME, `/purchaseOrders/${poId}`, options);
  });
};

const getAllPurchaseOrders = (buyerId) => {
  return requestWrapper((options) => {
    // make api request
    Object.assign(options);
    return API.get(SCS_API_NAME, `/purchaseOrders?buyerId=${buyerId}`, options);
  });
};

const getPurchaseOrder = (poId) => {
  return requestWrapper((options) => {
    // make api request
    Object.assign(options);
    return API.get(SCS_API_NAME, `/purchaseOrders/${poId}`, options);
  });
};

const deletePurchaseOrder = (poId) => {
  return requestWrapper((options) => {
    // make api request
    Object.assign(options);
    return API.del(SCS_API_NAME, `/purchaseOrders/${poId}`, options);
  });
};

const getPurchaseOrders = (params) => {
  return requestWrapper((options) => {
    let {
      startDate,
      endDate,
      dateType,
      customerCode,
      vendorCode,
      buyerId
    } = params;
    // format date types
    const fromDateParam = dateType === "0"
      ? 'loadDateFrom'
      : 'arrivalDateFrom';
    const toDateParam = dateType === "0"
      ? 'loadDateTo'
      : 'arrivalDateTo'

    // form url and queryParams
    let url = `/purchaseOrders?`; 
    let queryParams = [];
    if (startDate) queryParams.push(`${fromDateParam}=${startDate}`);
    if (endDate) queryParams.push(`${toDateParam}=${endDate}`);
    if (vendorCode) queryParams.push(`vendorId=${vendorCode}`);
    if (customerCode) queryParams.push(`customerId=${customerCode}`);
    if (buyerId) queryParams.push(`buyerId=${buyerId}`);
    const queryString = queryParams.join('&');

    return API.get(SCS_API_NAME, `${url}${queryString}`, options);
  });
}

export {
  deletePurchaseOrder,
  getAllPurchaseOrders,
  getPurchaseOrders,
  putPurchaseOrder,
  getPurchaseOrder
};
