/* eslint react/no-unused-state: 1 */
/* eslint no-undef: 1 */

import 'react-dates/initialize';
import React from 'react';
import { Logger, Auth } from 'aws-amplify';
import { getVendorCoolers, getCountryOfOrigins } from '../services/api/P3ApiService';
import { keyValueMapper } from '../utilities/key-value-util';

import 'react-dates/lib/css/_datepicker.css';
import './Dashboard.scss';

import Search from './Search';

const logger = new Logger('Dashboard.js');

class Dashboard extends React.Component {
  constructor() {
    super();

    this.state = { 
      countryOfOrigins: {},
      vendorCoolers: {}
    };
  }

  componentDidMount = async () => {
    Auth.currentAuthenticatedUser().then((user) => {
      logger.info('Logged In User', user.signInUserSession.idToken.payload);
      this.setState({ currentUser: user.signInUserSession.idToken.payload.email });
    })
    .catch((error) => {
      logger.error('User is not authenticated ', error);
      throw error;
    });
    await getVendorCoolers().then((vendorCoolersDetails) => {
      const vendorCoolers = keyValueMapper(
        vendorCoolersDetails,
        'code',
        'code',
      );
      logger.debug('coolers mapped', vendorCoolers);
      this.setState({ vendorCoolers, vendorCoolersDetails });
    });
    await getCountryOfOrigins().then((cooDetails) => {
      const countryOfOrigins = keyValueMapper(
        cooDetails,
        'name',
        'name',
      );
      logger.debug('coo mapped', countryOfOrigins);
      this.setState({ countryOfOrigins, cooDetails });
    });
  }

  render() {
    const {
      countryOfOrigins, vendorCoolers, currentUser
    } = this.state;

    return (
      <div className="App">
        <Search currentUser={currentUser} countryOfOrigins={countryOfOrigins} vendorCoolers={vendorCoolers} />
      </div>
    );
  }
}

export default Dashboard;
