/* eslint react/jsx-indent: 1 */
/* eslint react/jsx-indent-props: 1 */
/* eslint react/no-unused-state: 1 */
/* eslint no-undef: 1 */

import 'react-dates/initialize';
import React from 'react';
import moment from 'moment';
import { Logger } from 'aws-amplify';
import { Pulse } from 'wf-react-component-library';
import { putPurchaseOrder, getPurchaseOrder, deletePurchaseOrder} from '../services/api/SCSApiService'
import { postPurchaseOrder } from '../services/api/ConfirmOrderApiService';
import PurchaseOrderItemDetails from './PurchaseOrderItemDetails';
import { getPurchaseOrderItemDetails } from '../services/api/P3ApiService';
import { getPricingStatus } from '../utilities/pricing-status';
import downarrow from '../icons/down-arrow.svg';
import uparrow from '../icons/up-arrow.svg';

import './PurchaseOrderDetails.scss';

const logger = new Logger('PurchaseOrderDetails.js');

class PurchaseOrderDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPurchaseOrder: null,
      vendorName: 'Nicole',
      selectedPurchaseOrderDetails: [],
      selectedPurchaseOrder: null,
      orderValidations: {},
      sortBy: 'arrivalDate',
      sortDesc: true,
      sortedItems: this.props.purchaseOrderDetails.sort((a, b) => (a['arrivalDate'] > b['arrivalDate'] ? 1 : -1))
    };
    
    this.formatDate = this.formatDate.bind(this);
    this.getCustomerName = this.getCustomerName.bind(this);
    this.showPurchaseOrderItems = this.showPurchaseOrderItems.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleApproveReject = this.handleApproveReject.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCheckBoxClick = this.handleCheckBoxClick.bind(this);
    this.formatToP3JSON = this.formatToP3JSON.bind(this);
    this.comparePurchaseOrderValues = this.comparePurchaseOrderValues.bind(this);
    this.handleSort = this.handleSort.bind(this);
  }

  componentDidUpdate = (prevProps) => {
    const { searchId } = this.props;
    if (searchId !== prevProps.searchId) {
      const sortedItems = this.props.purchaseOrderDetails.sort((a, b) => (a['arrivalDate'] > b['arrivalDate'] ? 1 : -1));
      this.setState({ 
        sortedItems,
        sortBy: 'arrivalDate',
        sortDesc: true
      });
    }
  }

  formatDate = (myDt) => {
    const dt = moment(myDt);
    return dt.format('MM/DD/YYYY');
  }

  getCustomerName = (customerCode) => {
    const { customerDetails } = this.props;
    const cust = customerDetails.filter(customer => customer.code === customerCode);
    return cust.length > 0 ? cust[0].description : 'No Description';
  }

  comparePurchaseOrderValues = (dynamoOrder, p3Order) => {
    // map over dynamo order data to compare with p3 values.
    // we're constructing an array with p3 values to pass the PurchaseOrderItemDetails component
    // to display in tool tips.
    const orderValidations = dynamoOrder.map((order, index) => {
      const validations = {};
      if (order.originalQty !== p3Order[index].arQty) {
        validations.originalQty = p3Order[index].arQty;
      }

      if (order.originalPrice !== p3Order[index].price) {
        validations.originalPrice = p3Order[index].price;
      }

      if (order.originalTotalWeight !== p3Order[index].totalWeight) {
        validations.originalTotalWeight = p3Order[index].totalWeight;
      }

      const item = p3Order[index];
      const p3CountryOfOrigin = item.item ? (item.overrideOriginName === null ? item.item.originName : item.overrideOriginName) : (item.overrideOriginName === null ? '' : item.overrideOriginName);

      console.log(`The original COO is ${order.originalCountryOfOrigin} and the p3 COO is ${p3CountryOfOrigin}`)
      
      if (order.originalCountryOfOrigin !== p3CountryOfOrigin) {
        validations.originalCountryOfOrigin = p3CountryOfOrigin;
      }

      return validations;
    });

    this.setState({ orderValidations });
  }

  showPurchaseOrderItems = (selectedPurchaseOrder) => {   
    const { purchaseOrderDetails, vendorDetails } = this.props;

    let purchaseOrderItemDetails = null;
    let vendorName = null;
    let selectedPurchaseOrderDetails = null;

    if (selectedPurchaseOrder !== null) {
      purchaseOrderItemDetails = purchaseOrderDetails.filter(po => selectedPurchaseOrder.toString() === po.poId.toString())[0].poDetail;
      selectedPurchaseOrderDetails = purchaseOrderDetails.filter((po) => selectedPurchaseOrder.toString() === po.poId.toString())[0];

      getPurchaseOrderItemDetails(selectedPurchaseOrderDetails.poId)
      .then((p3PurchaseOrderDetails) => {
        this.comparePurchaseOrderValues(selectedPurchaseOrderDetails.poDetail, p3PurchaseOrderDetails);
      });

      const vendorCode = purchaseOrderDetails.filter(po => selectedPurchaseOrder.toString() === po.poId.toString())[0].vendorId;
      vendorName = vendorDetails.filter(vendor => vendor.code === vendorCode)[0].description;
    }

    this.setState({
      selectedPurchaseOrder, purchaseOrderItemDetails, vendorName, selectedPurchaseOrderDetails
    });
  }

  handleDelete = (selectedPurchaseOrder) => {
    if (selectedPurchaseOrder != null) {
      deletePurchaseOrder(selectedPurchaseOrder).then(() => {
        logger.debug('Delete was successful');
        this.setState({
          selectedPurchaseOrder: null,
          purchaseOrderItemDetails: null,
          selectedPurchaseOrderDetails: null,
          isSaving: false
        });
        this.props.handleSearch();
      })
      .catch(() => {
        console.log('Error on Delete');
      });
    }
  }

  handleApproveReject = (rejectionNote = null) => {
    this.setState({ showConfirmationModal: false, isSaving: true, searchItems: []});

    const poItemDetails = [];
    const { purchaseOrderDetails, currentUser } = this.props;
    const { selectedPurchaseOrderDetails, selectedPurchaseOrder } = this.state;

    // Get the PO(s) that is being approved/rejected
    const po = purchaseOrderDetails.filter(po => po.poId.toString() === selectedPurchaseOrder.toString())[0];

    // item details are in string format so we need to convert to JSON
    const itemDetails = po.poDetail;

    // loop through each item that is part of the PO
    itemDetails.forEach(poItemDetail => {
      
      // if item was approved/rejected, attach the "new" properties
      if (selectedPurchaseOrderDetails.length > 0 && selectedPurchaseOrderDetails.includes(poItemDetail.poDetailId.toString())) {
        poItemDetail['confirmationDate'] = new Date(new Date().getFullYear(),new Date().getMonth() , new Date().getDate());
        poItemDetail['isApproved'] = rejectionNote === null ? true : false;
      }

      poItemDetails.push(poItemDetail);
    });

    // stringify the object for storage in the Dynamo DB
    if (rejectionNote) po['rejectionNote'] = rejectionNote;
    po['poDetail'] = JSON.stringify(poItemDetails);
    po['updatedDate'] = new Date().toISOString().split('.')[0]+"Z";
    po['updatedBy'] = currentUser
    
    // Save the data to Dynamo
    putPurchaseOrder(selectedPurchaseOrder, po).then(() => {
      // Check if all items have been Approved/Rejected
      getPurchaseOrder(selectedPurchaseOrder).then((po) => {
        const items = JSON.parse(po.poDetail);
        const approvedRejectedItems = items.filter(item => item.confirmationDate);
        if (items.length === approvedRejectedItems.length) {
          // If all items have been approved/rejected
          const pricingStatus = getPricingStatus(po);
          if (pricingStatus.isSubmittableToP3) {
            var P3PoJson = this.formatToP3JSON(po, items);
            if (P3PoJson != null) {
              logger.debug('Json send to P3 ..', JSON.stringify(P3PoJson));
              postPurchaseOrder(P3PoJson)
                .then(() => {
                  this.handleDelete(selectedPurchaseOrder);
                })
                .catch(() => {
                  console.log('Error on handleApproveReject');
                });
            }
          } else {
            this.handleDelete(selectedPurchaseOrder);
          }
        } else {
          this.props.handleSearch();
        }
      });
    });
  }

  formatToP3JSON = (selectedPurchaseOrderDetails, poDetails) => {  
    const { currentUser } = this.props;
    if (selectedPurchaseOrderDetails !== null){
      var P3PoJson = {};
      P3PoJson.poId = selectedPurchaseOrderDetails.poId;
      P3PoJson.pickupNumber = selectedPurchaseOrderDetails.pickupNumber;
      //formating date to ISO 8061 without milliseconds for P3 to work.
      P3PoJson.loadDate = new Date(selectedPurchaseOrderDetails.loadDate.concat('T00:00:00')).toISOString().split('.')[0]+"Z";
      P3PoJson.arrivalDate = new Date(selectedPurchaseOrderDetails.arrivalDate.concat('T00:00:00')).toISOString().split('.')[0]+"Z";
      P3PoJson.createdBy = selectedPurchaseOrderDetails.createdBy;
      P3PoJson.createdDate = new Date(selectedPurchaseOrderDetails.createdDate).toISOString().split('.')[0]+"Z";
      P3PoJson.coolerCode = selectedPurchaseOrderDetails.coolerCode;
      P3PoJson.updateOM = currentUser; 
      P3PoJson.vendorInvoiceNumber= selectedPurchaseOrderDetails.invoiceNumber ? selectedPurchaseOrderDetails.invoiceNumber : null;
      P3PoJson.rejectionNote = selectedPurchaseOrderDetails.rejectionNote ? selectedPurchaseOrderDetails.rejectionNote : null;
      P3PoJson.poDetails = poDetails.map(pod => {
        return {
          'id':pod.poDetailId,
          'arQty' : 'qty' in pod !== undefined ? pod.qty : null,
          'price' : 'price' in pod ? pod.price : null,
          'countryOfOrigin': pod.countryOfOrigin ? pod.countryOfOrigin : null,
          'totalWeight': 'totalWeight' in pod ? pod.totalWeight : null,
          'isApproved': pod.isApproved
        }
      });

      return P3PoJson;
    }        
  }

  closeModal = () => {
    this.setState({ selectedPurchaseOrder: null, purchaseOrderItemDetails: null, selectedPurchaseOrderDetails: null });
  }

  handleCheckBoxClick = (selectedPurchaseOrderDetails, selectedPurchaseOrder) => {
    this.setState({ selectedPurchaseOrderDetails, selectedPurchaseOrder });
  };

  handleSort = (column) => {
    let { sortBy, sortDesc, sortedItems } = this.state;
    const { buyerDetails } = this.props;

    sortDesc = sortBy === column ? !sortDesc : true;

    if (column === 'buyerCode') {
      sortedItems.map(item => {
        item[column] = buyerDetails.filter(buyer => item.buyerId === buyer.buyerId)[0].description;
      });
    } else if (column === 'pricingStatusName') {
      sortedItems.map(item => {
        const pricingStatus = getPricingStatus(item);
        item[column] = pricingStatus.name;
      });
    } else if (column === 'category') {
      sortedItems.map(item => {
        item[column] = item.category ? item.category : null;
      });
    } else if (column === 'confirmedItems') {
      sortedItems.map(item => {
        const poDetailsData = (typeof sortedItems.filter(po => item.poId.toString() === po.poId.toString())[0].poDetail === 'string')
        ? JSON.parse(sortedItems.filter(po => item.poId.toString() === po.poId.toString())[0].poDetail)
        : sortedItems.filter(po => item.poId.toString() === po.poId.toString())[0].poDetail;

        item[column] = `${poDetailsData.filter(detail => detail.confirmationDate === undefined).length} of ${poDetailsData.length}`;
        item['remainingItemCount'] = poDetailsData.filter(detail => detail.confirmationDate === undefined).length;
        item['totalItemCount'] = poDetailsData.length;
      })
    } else if (column === 'lastUpdated') {
      sortedItems.map(item => {
        item[column] = `${item.updatedBy ? item.updatedBy.split('@')[0] : item.createdBy.split('@')[0]} - ${item.updatedDate ? this.formatDate(item.updatedDate) : this.formatDate(item.createdDate)}`;
        item['updatedByName'] = item.updatedBy ? item.updatedBy.split('@')[0].toLowerCase() : item.createdBy.split('@')[0].toLowerCase();
        item['updatedByDate'] = item.updatedDate ? item.updatedDate : item.createdDate;
      });
    }

    if (['poId'].includes(column)) {
      sortDesc 
        ? sortedItems.sort((a, b) => parseInt(a[column], 10) - parseInt(b[column], 10))
        : sortedItems.sort((a, b) => parseInt(b[column], 10) - parseInt(a[column], 10));
    } else if (column === 'confirmedItems') {
      sortDesc 
        ? sortedItems.sort((a, b) => parseInt(a['remainingItemCount'], 10) - parseInt(b['remainingItemCount'], 10)
          || parseInt(a['totalItemCount'], 10) - parseInt(b['totalItemCount'], 10))
        : sortedItems.sort((a, b) => parseInt(b['remainingItemCount'], 10) - parseInt(a['remainingItemCount'], 10)
          || parseInt(b['totalItemCount'], 10) - parseInt(a['totalItemCount'], 10));
    } else if (column === 'lastUpdated') {
      sortDesc 
        ? sortedItems.sort((a, b) => (a['updatedByName'] > b['updatedByName'] ? 1 : -1)
          || (a['updatedByDate'] > b['updatedByDate'] ? 1 : -1))
        : sortedItems.sort((a, b) => (a['updatedByName'] < b['updatedByName'] ? 1 : -1)
          || (a['updatedByDate'] < b['updatedByDate'] ? 1 : -1));
    } else {
      sortDesc 
        ? sortedItems.sort((a, b) => (a[column] > b[column] ? 1 : -1))
        : sortedItems.sort((a, b) => (a[column] < b[column] ? 1 : -1));
    }

    if (sortBy === column) {
      // change sort order
      this.setState({ sortDesc, sortedItems });
    } else {
      this.setState({ sortBy: column, sortDesc, sortedItems });
    }
  };

  render() {
    const { selectedPurchaseOrder, purchaseOrderItemDetails, vendorName, orderValidations, sortBy, sortDesc, sortedItems, isSaving } = this.state;
    const { purchaseOrderDetails, buyerDetails } = this.props;
    let rejectionNote = '';

    if (selectedPurchaseOrder) {
      const po = sortedItems.filter(po => po.poId.toString() === selectedPurchaseOrder.toString())[0];
      if (po.rejectionNote) rejectionNote = po.rejectionNote;
    }

    const sortColumn = (column, label) => {
      return sortBy === column ? <img
        className="sort-order-image"
        src={sortDesc ? downarrow : uparrow}
        alt={
          sortDesc
            ? `sort by ${label} desc`
            : `sort by ${label} asc`
        }
        height="15"
        width="15"
      />
      : null
    }

    return (
      <div className="flex-container-row item-table-container show">

      { isSaving
        ? <Pulse text="Saving Purchase Order Updates..." />
        : <div className="flex-container-column">
              <table className="item-table" cellPadding="0" cellSpacing="0" width="100%">
                  <colgroup>
                      <col span="1" style={{ width: '5%' }} />
                      <col span="1" style={{ width: '5%' }} />
                      <col span="1" style={{ width: '5%' }} />
                      <col span="1" style={{ width: '4%' }} />
                      <col span="1" style={{ width: '4%' }} />
                      <col span="1" style={{ width: '4%' }} />
                      <col span="1" style={{ width: '4%' }} />
                      <col span="1" style={{ width: '4%' }} />
                      <col span="1" style={{ width: '5%' }} />
                      <col span="1" style={{ width: '4%' }} />
                      <col span="1" style={{ width: '9%' }} />
                      <col span="1" style={{ width: '20%' }} />
                  </colgroup>
                  <tbody>
                      <tr className="item-table-header">
                          <td className="sort-column" onClick={() => this.handleSort('poId')}>
                            XPO Number
                            {sortColumn('poId', 'XPO Number')}
                          </td>
                          <td className="sort-column" onClick={() => this.handleSort('pickupNumber')}>
                            Pickup Number
                            {sortColumn('pickupNumber', 'Pickup Number')}
                          </td>
                          <td className="sort-column" onClick={() => this.handleSort('customerId')}>
                            Customer Code
                            {sortColumn('customerId', 'Customer')}
                          </td>
                          <td className="sort-column" onClick={() => this.handleSort('vendorId')}>
                            Vendor Code
                            {sortColumn('vendorId', 'Vendor')}
                          </td>
                          <td className="sort-column" onClick={() => this.handleSort('buyerCode')}>
                            Buyer
                            {sortColumn('buyerCode', 'Buyer')}
                          </td>
                          <td className="sort-column"  onClick={() => this.handleSort('pricingStatusName')}>
                            Pricing Status
                            {sortColumn('pricingStatusName', 'Pricing Status')}
                          </td>
                          <td className="sort-column" onClick={() => this.handleSort('loadDate')}>
                            Loaded Date
                            {sortColumn('loadDate', 'Load Date')}
                          </td>
                          <td className="sort-column" onClick={() => this.handleSort('arrivalDate')}>
                            Delivery Date
                            {sortColumn('arrivalDate', 'Arrival Date')}
                          </td>
                          <td className="sort-column" onClick={() => this.handleSort('category')}>
                            Category
                            {sortColumn('category', 'Category')}
                          </td>
                          <td className="sort-column" onClick={() => this.handleSort('confirmedItems')}>
                            Items Left For Review
                            {sortColumn('confirmedItems', 'Item Left for Review')}
                          </td>
                          <td className="sort-column" onClick={() => this.handleSort('lastUpdated')}>
                            Last Updated
                            {sortColumn('lastUpdated', 'Item Left for Review')}
                          </td>
                          <td>Notes</td>
                      </tr>

                      { sortedItems.map((item) => {
                          const pricingStatus = getPricingStatus(item);

                          const poDetailsData = (typeof sortedItems.filter(po => item.poId.toString() === po.poId.toString())[0].poDetail === 'string')
                            ? JSON.parse(sortedItems.filter(po => item.poId.toString() === po.poId.toString())[0].poDetail)
                            : sortedItems.filter(po => item.poId.toString() === po.poId.toString())[0].poDetail;

                          return (
                            <tr key={item.poId} className="item-row" onClick={() => this.showPurchaseOrderItems(item.poId)}>
                              <td>{item.poId}</td>
                              <td>{item.pickupNumber}</td>
                              <td>{item.customerId}</td>
                              <td>{item.vendorId}</td>
                              <td>{buyerDetails.filter(buyer => item.buyerId === buyer.buyerId)[0] ? buyerDetails.filter(buyer => item.buyerId === buyer.buyerId)[0].description : ''}</td>
                              <td>{pricingStatus.name}</td>
                              <td>{this.formatDate(item.loadDate)}</td>
                              <td>{this.formatDate(item.arrivalDate)}</td>
                              <td>{item.category ? item.category : null}</td>
                              <td>
                                {`${poDetailsData.filter(detail => detail.confirmationDate === undefined).length}
                                of 
                                ${poDetailsData.length}`}
                              </td>
                              <td>
                                {`${item.updatedBy ? item.updatedBy.split('@')[0] : item.createdBy.split('@')[0]} - 
                                ${item.updatedDate ? this.formatDate(item.updatedDate) : this.formatDate(item.createdDate)}`}
                              </td>
                              <td>{item.rejectionNote ? item.rejectionNote : null}</td>
                          </tr>)
                        })
                      }
                  </tbody>
              </table>
          </div>
        }

        {selectedPurchaseOrder !== null 
        ? (
          <PurchaseOrderItemDetails
            orderValidations={orderValidations}
            purchaseOrderItemDetails={purchaseOrderItemDetails}
            purchaseOrderDetails={purchaseOrderDetails.filter(po => po.poId.toString() === selectedPurchaseOrder.toString())[0]}
            vendorName={vendorName}
            poNumber={selectedPurchaseOrder}
            rejectionNote={rejectionNote}
            closeModal={this.closeModal}
            handleApprove={this.handleApproveReject}
            handleReject={this.handleApproveReject}
            handleCheckBoxClick={this.handleCheckBoxClick}
          />
        ) : null}
      </div>
    );
  }
}

export default PurchaseOrderDetails;
