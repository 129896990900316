/* eslint-disable arrow-body-style */
import { API, Auth, Logger } from 'aws-amplify';

const logger = new Logger('P3ApiService');
const P3_API_NAME = 'P3ApiProxy';

/**
 * Request Options to include authorization headers on all requests
 */
const requestOptions = () => {
  logger.debug('requestOptions..');
  return Auth.currentAuthenticatedUser()
    .then((user) => {
      return {
        headers: {
          'Content-Type': 'application/json',
          'idToken': `${user.signInUserSession.idToken.jwtToken}`,
        },
      };
    })
    .catch((error) => {
      logger.error('User is not authenticated ', error);
      throw error;
    });
};

/**
 * Request Wrapper to call the request options on all requests
 * @param {*} request
 */
const requestWrapper = (request) => {
  return requestOptions().then((options) => request(options));
};

/**
 * Return a page of vendors
 * this service returns a sorted and filtered list of vendor to make it
 * easier for the client to consume the result
 * @param {*} params
 */
const getVendors = (params = { size: 2500, sort: 'description,asc' }) => {
  return requestWrapper((options) => {
    // set query params if any
    Object.assign(options, { queryStringParameters: params });
    // make api request, then filter the result to return only active ones
    return API.get(P3_API_NAME, '/vendors', options).then((result) => {
      return result.filter(
        (v) => (!v.inactive && !v.description.includes('DO NOT USE'))
      );
    });
  });
};

const getCategories = (params = { size: 200, sort: 'name,asc' }) => {
  return requestWrapper((options) => {
    // set query params if any
    Object.assign(options, { queryStringParameters: params });
    // make api request, then filter the result to return only active ones
    return API.get(P3_API_NAME, '/categories', options);
  });
};

/**
 * Return a page of locations
 * this service returns a list of Locations, by default the list is sorted by code
 * @param {*} params
 */
const getLocations = (params = { size: 200, sort: 'code,asc' }) => {
  return requestWrapper((options) => {
    // set query params if any
    Object.assign(options, { queryStringParameters: params });
    // make api request, then filter the result to return only active ones
    return API.get(P3_API_NAME, '/locations', options);
  });
};

/**
 * Return a page of Country of Origins
 * this service returns a list of Country of Origins, by default the list is sorted by name
 * @param {*} params
 */
const getCountryOfOrigins = (params = { size: 200, sort: 'name,asc' }) => {
  return requestWrapper((options) => {
    // set query params if any
    Object.assign(options, { queryStringParameters: params });
    // make api request, then filter the result to return only active ones
    return API.get(P3_API_NAME, '/countryOfOrigins', options);
  });
};

const getUserByEmail = (email, params = {}) => {
  return requestWrapper((options) => {
    // set query params if any
    Object.assign(options, { queryStringParameters: params });
    // make api request, then filter the result to return only active ones
    return API.get(P3_API_NAME, `/users?email=${email}`, options);
  });
};

/**
 * Return a page of Customers
 * this service returns a list of Customers, by default the list is sorted by name
 * @param {*} params
 */
const getCustomers = (params = { size: 1000, sort: 'description,asc' }) => {
  return requestWrapper((options) => {
    // set query params if any
    Object.assign(options, { queryStringParameters: params });
    // make api request, then filter the result to return only active ones
    return API.get(P3_API_NAME, '/customers', options);
  });
};

const getBuyers = (params = { size: 2500, sort: 'description,asc' }) => {
  return requestWrapper((options) => {
    // set query params if any
    Object.assign(options, { queryStringParameters: params });
    // make api request, then filter the result to return only active ones
    return API.get(P3_API_NAME, '/buyers', options);
  });
};

/**
 * Return a page of coolers specific to a vendors
 * this service returns a list of coolers specific to the supplied vendors, by default the list is sorted by code
 * @param {*} params
 */
const getVendorCoolers = (params = { size: 1000, sort: 'code,asc' }) => {
  return requestWrapper((options) => {
    //TODO TODO - VendorCode needs to be passed once the modules to create vendor profile is completed.
    const vendorCode = params.vendorCode || 'CA';
    // set query params if any
    Object.assign(options, { queryStringParameters: params });
    // make api request, then filter the result to return only active ones
    return API.get(P3_API_NAME, '/vendors/'+vendorCode+'/coolers', options).then((result) => {
      return result.filter((v) => (!v.inactive));
    });
  });
};

const getShipments = (params) => {
  return requestWrapper((options) => {
    let {
      startDate,
      endDate,
      dateType,
      customerCode,
      vendorCode,
      buyerId
    } = params;
    // format date types
    const fromDateParam = dateType === "0"
      ? 'loadDateFrom'
      : 'arrivalDateFrom';
    const toDateParam = dateType === "0"
      ? 'loadDateTo'
      : 'arrivalDateTo'

    // form url and queryParams
    let url = `/shipmentSearch?`; 
    let queryParams = [];
    if (startDate) queryParams.push(`${fromDateParam}=${startDate}`);
    if (endDate) queryParams.push(`${toDateParam}=${endDate}`);
    if (vendorCode) queryParams.push(`vendorId=${vendorCode}`);
    if (customerCode) queryParams.push(`customerId=${customerCode}`);
    if (buyerId) queryParams.push(`buyerId=${buyerId}`);
    const queryString = queryParams.join('&');

    return API.get(P3_API_NAME, `${url}${queryString}`, options);
  });
}


const getPurchaseOrderItemDetails = (purchaseOrderId) => {
  return requestWrapper((options) => {
    // GET /v1/purchaseOrders/{poId}/details
    const vendorCode = 'CA';
    return API.get(P3_API_NAME, `/purchaseOrders/${purchaseOrderId}/details`, options);
  });
};

const getPurchaseOrderHeaders = (purchaseOrderId) => {
  return requestWrapper((options) => {
    return API.get(P3_API_NAME, `/purchaseOrders/${purchaseOrderId}`, options);
  });
}

const getWorkOrderHeaders = (workOrderIds) => {
  return requestWrapper((options) => {
    return API.get(P3_API_NAME, `/workOrders?workOrderIds=${workOrderIds}`, options);
  });
}

export {
  requestWrapper,
  getVendors,
  getCategories,
  getLocations,
  getCountryOfOrigins,
  getCustomers,
  getVendorCoolers,
  getShipments,
  getPurchaseOrderItemDetails,
  getBuyers,
  getUserByEmail,
  getPurchaseOrderHeaders,
  getWorkOrderHeaders
};
