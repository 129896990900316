/* eslint react/jsx-indent: 1 */
/* eslint react/jsx-indent-props: 1 */

import React from 'react';
import { PrimaryButton, Modal, Tooltip } from 'wf-react-component-library';
import currencyFormatter from '../utilities/currency-formatter';
import { Logger } from 'aws-amplify';
import checkmark from '../icons/checkmark.svg';
import edit from '../icons/edit.svg';
import { getPricingStatus } from '../utilities/pricing-status';

import './PurchaseOrderItemDetails.scss';

const logger = new Logger('PurchaseOrderItemDetails.js');

const P3_TOOLTIP_MSG = 'P3 currently at';

class PurchaseOrderItemDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectAllChecked: false,
      selectedItems: [],
      rejectionNotes: [],
      rejectionNote: this.props.rejectionNote,
      showRejectionNote: false,
      errMsg: '',
      itemDetails: this.props.purchaseOrderItemDetails,
      showOpenItems: false,
      itemDetailsBackup: [],
      showToolTip: false
    };

    this.handleSelectAllCheckBoxClick = this.handleSelectAllCheckBoxClick.bind(this);
    this.handleCheckBoxClick = this.handleCheckBoxClick.bind(this);
    this.allItemsConfirmed = this.allItemsConfirmed.bind(this);
    this.handleReject = this.handleReject.bind(this);
    this.handleApprove = this.handleApprove.bind(this);
    this.handleRejectionNoteChange = this.handleRejectionNoteChange.bind(this);
    this.submitRejection = this.submitRejection.bind(this);
    this.hasSelectedItems = this.hasSelectedItems.bind(this);
    this.setConfirmationDate = this.setConfirmationDate.bind(this);
    this.handleshowOpenItems = this.handleshowOpenItems.bind(this);
    this.showTooltip = this.showTooltip.bind(this);
    this.hideTooltip = this.hideTooltip.bind(this);
  }

  showTooltip = () => {
    this.setState({ showToolTip: true });
  }

  hideTooltip = () => {
    this.setState({ showToolTip: false });
  }

  handleCheckBoxClick = (e) => {
    const currentItem = e.target.value.toString();
    let { selectedItems } = this.state;
    const { poNumber } = this.props;

    if (selectedItems.includes(currentItem)) {
      // remove from the array
      selectedItems = selectedItems.filter((item) => item !== currentItem);
      this.setState({ selectedItems, selectAllChecked: false, errMsg: '' });
    } else {
      selectedItems.push(currentItem);
      this.setState({
        selectedItems,
        selectAllChecked: false,
        errMsg: ''
      });
    }

    this.props.handleCheckBoxClick(selectedItems, poNumber);
  }

  handleSelectAllCheckBoxClick = () => {
    const { selectAllChecked } = this.state;
    const { purchaseOrderItemDetails, poNumber } = this.props;
    const items = purchaseOrderItemDetails;
    let selectedItems = []

    if (selectAllChecked) {
      this.setState({
        selectedItems,
        selectAllChecked: false,
        errMsg: ''
      });
    } else {
      selectedItems = items.map((item) => item.poDetailId.toString());
      this.setState({
        selectAllChecked: true,
        selectedItems,
        errMsg: ''
      });
    }

    this.props.handleCheckBoxClick(selectedItems, poNumber);
  }

  allItemsConfirmed = () => {
    const { itemDetails } = this.state;

    const approvedRejectedItems = itemDetails.filter(item => item.confirmationDate);

    return itemDetails.length === approvedRejectedItems.length;
  }

  submitRejection = () => {
    const { rejectionNote } = this.state;
    const originalRejectionNote = this.props.rejectionNote;

    if (rejectionNote.length === 0 || rejectionNote === originalRejectionNote) return false;

    return true;
  }

  hasSelectedItems = () => {
    const { selectedItems } = this.state;

    if (selectedItems.length === 0 && !this.allItemsConfirmed()) {
      this.setState( {errMsg:'Please select at least one item to Approve/Reject.'});
      return false;
    }

    return true;
  }

  setConfirmationDate = (rejectionNote = null) => {
    const { selectedItems, itemDetails} = this.state;

    const updatedItems = itemDetails.map(item => {
      if (selectedItems.includes(item.poDetailId.toString())) {
        item.confirmationDate = new Date(new Date().getFullYear(),new Date().getMonth() , new Date().getDate());
        item.isApproved = rejectionNote === null ? true : false;
      } 
      return item;
    });

    this.setState({itemDetails: updatedItems, selectedItems: [], selectAllChecked: false, errMsg: '', showRejectionNote: false });

    if (this.allItemsConfirmed()) {
      this.props.closeModal();
    }
  }

  handleReject = () => {
    //check there is an item selected
    if (this.hasSelectedItems()) {
      const { rejectionNote, showRejectionNote } = this.state;

      // showRejectionNote === true, the user has clicked the Reject button again after entering a rejection note
      if (showRejectionNote) {
        if (this.submitRejection()) {
          this.props.handleReject(rejectionNote);
          this.setConfirmationDate(rejectionNote);
        } else {
          const msg = "You must enter/update the rejection note.";
          this.setState({ errMsg: msg, showRejectionNote: true });
        }
      } else {
        // showRejectionNote === false the first time the Reject button is clicked
        this.setState({ errMsg: '', showRejectionNote: true});
      }
    }
  }

  handleApprove = () => {
    //check there is an item selected
    if (this.hasSelectedItems()) {
      this.props.handleApprove();
      this.setConfirmationDate();
    }
  }

  handleRejectionNoteChange = (e) => {
    const rejectionNote = e.target.value;
    this.setState({rejectionNote});
  }

  handleshowOpenItems = () => {
    const { showOpenItems, itemDetails, itemDetailsBackup } = this.state; 
    
    if (showOpenItems) {
      // if showing only the open items, then change to show the open and closed items (approved and rejected)
      this.setState({ showOpenItems: false, itemDetails: itemDetailsBackup, itemDetailsBackup: []});
    } else {
      //if showing all items, show only the open items that need approval/rejection
      const items = itemDetails.filter(item => item.confirmationDate === undefined)
      this.setState({ showOpenItems: true, itemDetails: items, itemDetailsBackup: itemDetails});
    }
  }


  render() {
    const { selectAllChecked, selectedItems, rejectionNote, showRejectionNote, errMsg, itemDetails, showOpenItems, showToolTip } = this.state;
    const { purchaseOrderItemDetails, vendorName, poNumber, purchaseOrderDetails,  orderValidations } = this.props;

    const tooltipClassName = showToolTip === true ? 'tip active' : 'tip';

    const pricingStatus =  getPricingStatus(purchaseOrderDetails);
    const showRejectionButton = pricingStatus.isSubmittableToP3;

    return (
      <Modal show={purchaseOrderItemDetails !== null} width="90%">
        <div className="flex-container-row">
          <div className="flex-container-column ocd-item-header">{vendorName} - {poNumber} Shipment Confirmation</div>
        </div>
        <div className="flex-container-row header-details">
          <div className="flex-container-column no-width">
            <div className="flex-container-row hide-show">
              <div className="flex-container-column">
                <button className="toggle-closed-items" type="button" onClick={this.handleshowOpenItems}>{ showOpenItems ? 'Show All Items' : 'Show Open Items Only' }</button>
              </div>
            </div>
          </div>

          <div className="flex-container-column no-width">
            <div className="flex-container-row totals">
              <div className="flex-container-column po-header-details">
                Total Cases: {purchaseOrderDetails.calculatedCases}
              </div>
              <div className="flex-container-column po-header-details">
                Total Price: {currencyFormatter.format(purchaseOrderDetails.calculatedCost)}
              </div>
              <div className="flex-container-column po-header-details">
                Total Pallets: {purchaseOrderDetails.calculatedPallets}
              </div>
            </div>
          </div>
        </div>
        <div className="flex-container-row item-table-container show">
            <div className="flex-container-column">
                <table className="item-table" cellPadding="0" cellSpacing="0" width="100%">
                    <colgroup>
                        <col span="1" style={{ width: '1%' }} />
                        <col span="1" style={{ width: '3%' }} />
                        <col span="1" style={{ width: '17%' }} />
                        <col span="1" style={{ width: '3%' }} />
                        <col span="1" style={{ width: '3%' }} />
                        <col span="1" style={{ width: '3%' }} />
                        <col span="1" style={{ width: '3%' }} />
                        <col span="1" style={{ width: '3%' }} />
                        <col span="1" style={{ width: '3%' }} />
                        <col span="1" style={{ width: '3%' }} />
                        <col span="1" style={{ width: '4%' }} />
                        <col span="1" style={{ width: '4%' }} />
                        <col span="1" style={{ width: '4%' }} />
                    </colgroup>
                    <tbody>
                        <tr className="item-table-header">
                            <td className="checkbox-container">
                                <label className="body-short-form" htmlFor="1">
                                  <input
                                    type="checkbox"
                                    disabled={this.allItemsConfirmed()}
                                    id={1}
                                    value={1}
                                    checked={selectAllChecked}
                                    onChange={this.handleSelectAllCheckBoxClick}
                                  />
                                  <span />
                                </label>
                            </td>
                            <td>Item Code</td>
                            <td>Description</td>
                            <td className="qty-color" style={{paddingLeft: "0px !important"}}>Order Qty</td>
                            <td className="confirmed-header" style={{paddingLeft: "0px !important"}}>Confirmed Qty</td>
                            <td className="weight-color" style={{paddingLeft: "0px !important"}}>Weight</td>
                            <td className="confirmed-header" style={{paddingLeft: "0px !important"}}>Confirmed Weight</td>
                            <td className="weight-color" style={{paddingLeft: "0px !important"}}>Unit</td>
                            <td className="location-color" style={{paddingLeft: "0px !important"}}>COO</td>
                            <td className="confirmed-header" style={{paddingLeft: "0px !important"}}>Confirmed COO</td>
                            <td className="price-color">Buy Price</td>
                            <td className="confirmed-header">Confirmed Price</td>
                        </tr>
                        { itemDetails.map ((item, index) => {
                        const orderValidation = orderValidations[index];
                        const originalPrice = 'originalPrice' in item ? currencyFormatter.format(item.originalPrice) : '';
                        const isBurdenItem = (item.groupCode === "M" || item.groupCode === "MB" || item.groupCode === "SB") ? true : false;
                        return (<tr key={item.poDetailId}>
                          <td className="checkbox-container">
                              {item.confirmationDate ?
                                (item.isApproved ? 
                                  <img className="approved-icon" src={checkmark}></img> 
                                  : 
                                  <img className="rejected-icon" src={edit}></img>
                                )
                              :
                                <label className="body-short-form" htmlFor={item.poDetailId}>
                                  <input
                                    type="checkbox"
                                    id={item.poDetailId}
                                    value={item.poDetailId}
                                    checked={
                                      selectAllChecked ||
                                      selectedItems.includes(item.poDetailId.toString())
                                    }
                                    onChange={this.handleCheckBoxClick}
                                  />
                                  <span />
                                </label>
                              }
                            </td>  
                            <td style={{textAlign: "left", paddingLeft: "5px"}}>{item.itemCode}</td>
                            <td style={{textAlign: "left", paddingLeft: "5px"}}>{item.description}</td>
                            <td className="qty-color">
                              {orderValidation && orderValidation.originalQty !== undefined && item.originalQty !== undefined ? <span><Tooltip tooltip={`${P3_TOOLTIP_MSG} ${orderValidation.originalQty}`} tip={`${item.originalQty || ''}*`} tooltipClassName={tooltipClassName} onMouseEnter={this.showTooltip} onMouseLeave={this.hideTooltip} /></span> : item.originalQty}
                            </td>
                            <td className="confirmed-color">{item.qty}</td>
                            <td className="weight-color">
                              {orderValidation && orderValidation.originalTotalWeight !== undefined && item.originalTotalWeight !== undefined ? <span><Tooltip tooltip={`${P3_TOOLTIP_MSG} ${orderValidation.originalTotalWeight}`} tip={`${item.originalTotalWeight || ''}*`} tooltipClassName={tooltipClassName} onMouseEnter={this.showTooltip} onMouseLeave={this.hideTooltip} /></span> : item.originalTotalWeight}
                            </td>
                            <td className="confirmed-color">{item.totalWeight}</td>
                            <td className="confirmed-color">{item.unitType}</td>
                            <td className="location-color">
                                {isBurdenItem ? null : orderValidation && orderValidation.originalCountryOfOrigin !== undefined && item.originalCountryOfOrigin !== undefined ? <span><Tooltip tooltip={`${P3_TOOLTIP_MSG} ${orderValidation.originalCountryOfOrigin}`} tip={`${item.originalCountryOfOrigin || ''}*`} tooltipClassName={tooltipClassName} onMouseEnter={this.showTooltip} onMouseLeave={this.hideTooltip} /></span> : item.originalCountryOfOrigin}
                            </td>
                            <td className="confirmed-color">{isBurdenItem ? null : item.countryOfOrigin}</td>
                            <td className="price-color">
                                {orderValidation && orderValidation.originalPrice !== undefined && originalPrice !== undefined ? <span><Tooltip tooltip={`${P3_TOOLTIP_MSG} ${currencyFormatter.format(orderValidation.originalPrice)}`} tip={`${originalPrice}*`} tooltipClassName={tooltipClassName} onMouseEnter={this.showTooltip} onMouseLeave={this.hideTooltip} /></span> : originalPrice}
                            </td>
                            <td className="confirmed-color">{item.price !== undefined ? currencyFormatter.format(item.price) : ''}</td>
                        </tr>)}
                        )}
                    </tbody>
                </table>
            </div>
            { (errMsg.length > 0)
              ? <div className="error-msg">{errMsg}</div>
              : null
            }
            { showRejectionButton
              ? null
              : <div className="error-msg">{'This invoice will NOT be submitted to P3 and will be deleted from SCD and will NOT be recoverable. Only invoices with a Pricing Status Code of "Open" or "Preliminary" can be submitted to P3.'}</div>
            }
            { showRejectionNote
              ? <div className="flex-container-row rejection-note">
                <div className="flex-container-column">
                  <div className="rejection-note-label">
                      Please enter/update the reason this item is being rejected:
                  </div>
                  <div className="rejection-note-text">
                      <textarea rows={5} required value={rejectionNote} className="rejection-note-text-area" onChange={this.handleRejectionNoteChange}>
                        {rejectionNote}               
                      </textarea>
                  </div>
                </div>
              </div>
              : null
            }
        </div>

      <div className="flex-container-row buttons">
        { (!showRejectionNote)
          ? <PrimaryButton onClick={this.handleApprove} name="Approve" />
          : null
        }  
        {showRejectionButton
          ? <PrimaryButton onClick={this.handleReject} name="Reject" />
          : null
        }
        <PrimaryButton onClick={this.props.closeModal} name="Close" />
      </div>

    </Modal>
    );
  }
}

export default PurchaseOrderItemDetails;
