//Input: Array of purchaseOrderDetails
//Weight Total = Sum of (Qty*Unit Weight) on all items on the PO
// Cost Total = 
//        Sum (Qty*Weight*Price)  on all commodity items with unit type 'WT' 
//          + Sum (Qty*Price) on all commodity items with unit type 'CTN' 
//          + Sum (Qty*Price) on all burden items

// Case Total = Sum of Qty on all commodity items on the PO
// Pallets per Item = Qty/(Ti*Hi) on the item
// Pallets Total = Sum of Pallets on PO

const calculatePurchaseOrderTotals = (purchaseOrders) => {
  
  purchaseOrders.forEach((purchaseOrder) => {
    //convert json to array of details
    purchaseOrder.poDetail = JSON.parse(purchaseOrder.poDetail);
    let totalCost=0;
    let totalWeight=0;
    let totalCases=0;
    let totalPallets=0;

    purchaseOrder.poDetail.forEach(function(item) {
      const quantity = (item.qty == null  ? item.originalQty : parseInt(item.qty) );
      const price = (item.price == null ? item.originalPrice : parseFloat(item.price) );
      const weight = (item.totalWeight == null) ? item.originalTotalWeight : parseFloat(item.totalWeight);

      totalCost += (item.unitType === 'WT' ? weight * price : quantity * price);
      totalWeight += ((item.unitType === 'WT' || item.unitType === 'CTN') ? quantity * weight : 0);
      totalCases += ((item.unitType === 'WT' || item.unitType === 'CTN') ? quantity : 0);
      const calcPallets = ((item.unitType === 'WT' || item.unitType === 'CTN') ? quantity/item.cartonPallet : 0);
      totalPallets += calcPallets;
    });

    purchaseOrder.calculatedCost=totalCost.toFixed(2);
    purchaseOrder.calculatedWeight=totalWeight.toFixed(2);
    purchaseOrder.calculatedCases=totalCases.toFixed(2);
    purchaseOrder.calculatedPallets=totalPallets.toFixed(1);
  });
  
  return;
};


export default calculatePurchaseOrderTotals;
export { calculatePurchaseOrderTotals};